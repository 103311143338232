<template>
  <div>
    <div class="row" v-if="stakingPlan">
      <div class="col--10 col--md-12">
        <div class="d-flex mb-16">
          <h1 class="headline--md text-bold text-uppercase">{{ stakingPlan.title }} Detail</h1>
          <!-- TODO: implement edit staking plan -->
          <!-- <router-link
            :to="{ name: 'PlanEdit', params: { programId: programId, id: stakingPlan.id } }"
            tag="button"
            class="btn btn--primary">Edit Plan</router-link> -->
        </div>

        <div class="box box--white box--top">
          <div class="statistic box__item">
            <p class="statistic__title">Plan name</p>
            <h2 class="headline--md mt-4 text-bold">{{ stakingPlan.title }}</h2>
          </div>

          <div class="statistic box__item">
            <p class="statistic__title">Hold period (months)</p>
            <h2 class="headline--md mt-4 text-bold">{{ stakingPlan.period }}</h2>
          </div>

          <!-- <div class="statistic box__item">
            <p class="statistic__title">Status</p>
            <h2 class="headline--md mt-4 text-uppercase text-success">success</h2>
          </div>

          <div class="statistic box__item">
            <p class="statistic__title">Go live on app</p>
            <label class="form-switch mt-10">
              <input type="checkbox" checked />
              <span></span>
            </label>
          </div> -->
        </div>

        <div v-if="stakingPlan.interests" class="table-wrapper table-wrapper--fluid mt-16">
          <table class="table table--striped unique-32">
            <thead>
              <tr>
                <th>No.</th>
                <th>
                  Interest Rate
                  <br />
                  <span class="sub-heading">(%)</span>
                </th>
                <th>
                  Min. Amount
                  <br />
                  <span class="sub-heading">(TOKO)</span>
                </th>
                <th>
                  Max. Amount
                  <br />
                  <span class="sub-heading">(TOKO)</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(interest, index) in stakingPlan.interests" :key="interest.id">
                <td>{{ index + 1 }}</td>
                <td>{{ interest.rate | rate_percent }}%</td>
                <td>{{ interest.minAmount | number_with_commas }}</td>
                <td>{{ interest.maxAmount | number_with_commas }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p v-else class="mt-16 text-center">No plan interest data</p>

        <!-- TODO: implement edti staking-plan -->
        <!-- <div class="d-flex justify-end">
          <button
            @click="isDeleteConfirmOpen = true"
            class="btn btn--red-bordered mt-16">
            Delete Plan
          </button>
        </div> -->
      </div>
    </div>

    <p v-else>No Plan Data</p>

    <confirm-dialog
      v-if="isDeleteConfirmOpen"
      :open.sync="isDeleteConfirmOpen"
      confirmText="Are you sure delete this plan?"
      confirmButtonText="Delete"
      @confirm="onDeletePlan"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  data() {
    return {
      isDeleteConfirmOpen: false
    }
  },

  components: {
    ConfirmDialog
  },

  computed: {
    ...mapState('staking', ['stakingPlan', 'programId'])
  },

  methods: {
    ...mapActions('staking', ['getStakingPlan']),

    onDeletePlan() {
      this.isDeleteConfirmOpen = false
    }
  },

  created() {
    this.getStakingPlan(this.$route.params.id)
  }
}
</script>
